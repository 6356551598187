import React from 'react';

import { ButtonTopicItemTypes, ButtonTypes } from '@src/typescriptGlobals/contentful.types';

import { ButtonPropTypes } from '../Button/Button.types';
import Button from '../Button/Button';

import { RenderButtonsPropTypes } from './RenderButtons.types';

const getButtonProps = (button: ButtonTypes): ButtonPropTypes => {
  const baseProps = {
    btnText: button?.text,
    ...(button?.url && { href: button?.url }),
    ...(button?.target && { target: button?.target }),
  };

  switch (button?.type) {
    case 'primary':
      return {
        ...baseProps,
        primary: true,
        href: button?.url,
      };
    case 'secondary':
      return {
        ...baseProps,
        secondary: true,
        href: button?.url,
      };
    case 'tertiary':
      return {
        ...baseProps,
        tertiary: true,
        href: button?.url,
      };
    case 'quaternary':
      return {
        ...baseProps,
        quaternary: true,
        href: button?.url,
      };
    default:
      return {
        ...baseProps,
        primary: true,
        href: button?.url,
      };
  }
};

const RenderButtons: React.FC<RenderButtonsPropTypes> = ({
  items,
  className,
  children,
  btnClassName,
  svgClassName,
}) => {
  if (!items) return null;

  return (
    <div
      className={`mt-[3rem] space-y-[1rem] sm:flex sm:flex-row sm:items-center sm:gap-[1.6rem] sm:space-y-[unset] [&>div>a]:w-[100%] ${className}`}
    >
      {items.map((button: ButtonTopicItemTypes) => {
        const props = getButtonProps(button);
        return (
          <Button
            key={button?.sys?.id}
            {...props}
            btnClassName={btnClassName}
            svgClassName={svgClassName}
          />
        );
      })}
      {children}
    </div>
  );
};

export default RenderButtons;
