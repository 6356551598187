'use client';
import React from 'react';

import Container from '@src/components/Shared/Container';
import NextImage from '@src/components/Shared/NextImage';
import InlineSVG from '@src/components/Shared/InlineSVG';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import RenderButtons from '@src/components/Shared/RenderButtons';

import { FeaturedProductsPropTypes } from './FeaturedProducts.types';

const onNavigate = (slug: string) => {
  return () => {
    window.open(slug, '_self');
  };
};

const FeaturedProducts: React.FC<FeaturedProductsPropTypes> = ({
  attr,
  products,
  objectFit = 'cover',
  className = '',
}) => {
  return (
    <Container
      elementId='related-products'
      data-test={attr}
      className={`mx-auto md:max-w-[80rem] lg:max-w-[101rem] lg:!px-[0] ${className}`}
    >
      <div
        className={`grid ${
          products.length < 3
            ? 'justify-center gap-[1.3rem] sm:flex'
            : 'grid-cols-1 gap-[1.3rem] sm:grid-cols-2 md:grid-cols-2 md:gap-[2.2rem] lg:grid-cols-3'
        }`}
      >
        {products.map((product) => {
          return (
            <div
              key={`${product?.sys?.id}-products`}
              onClick={onNavigate(product?.slug)}
              className='flex w-full cursor-pointer flex-col overflow-hidden rounded-[1rem] border-[0.1rem] border-border md:max-w-[31.5rem]'
            >
              <div className='bg-background-tertiary '>
                <NextImage
                  src={product?.mediaPrimary?.url}
                  alt={product?.mediaPrimary?.description ?? 'VALD Product'}
                  aspectRatio={product?.mediaPrimary?.width / product?.mediaPrimary?.height}
                  width={product?.mediaPrimary?.width}
                  objectFit={objectFit}
                />
              </div>

              <div className='bg-tertiary flex h-[100%] flex-col p-[2.2rem]'>
                <InlineSVG
                  className='![&>svg]:h-[unset] [&>svg]:!w-[unset]'
                  src={product?.logo?.url}
                />

                <p className='mt-[1.5rem] text-[1.6rem] font-[700] leading-[2.4rem] text-body-copy--dark'>
                  {product?.title}
                </p>
                <RenderBodyCopy
                  className='my-[2.4rem] font-[300] text-body-copy md:my-[1.8rem]'
                  bodyCopy={product?.bodyCopy?.json?.content}
                />
                <RenderButtons className='mt-auto' items={[product?.button]} />
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default FeaturedProducts;
